import { Component, OnInit} from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { ArticleType } from 'src/app/global/global.enum';
import { ArticleDTO } from 'src/app/models';
import { ArticleService } from 'src/app/service/article.service';
import { CommonService } from 'src/app/service/common.service';
import { MediaService } from 'src/app/service/media.service';


@Component({
  selector: 'app-articles',
  templateUrl: './articles.component.html',
  styleUrls: ['./articles.component.css']
})
export class ArticlesComponent implements OnInit {
  
  public articles: ArticleDTO[] = [];
  public articlesFull: ArticleDTO[] =[];
  public electricCarArticles: ArticleDTO[] =[];
  public prArticles: ArticleDTO[] =[];
  public articleType: any;

  public articleImages: any[] = [];

  public isParking: boolean = false;
  public show: {[key: number]: boolean} = {};

  constructor(
      private articleService: ArticleService,
      private translateService: TranslateService,
      private route: ActivatedRoute,
      private commonService: CommonService,
      private mediaService: MediaService,
      private domSanitizer: DomSanitizer
    ) { }

  ngOnInit(): void {
   this.articleType = this.route.snapshot.params.articleType;
   if(this.articleType != null && this.articleType === 'PARKING'){
      this.isParking = true;
   }else{
     this.isParking = false;
   }
 
   this.getArticles(this.articleType !=null ? this.articleType : 'NEWS', this.translateService.currentLang);
   this.getElectricCarArticles(this.translateService.currentLang);
   this.getPrArticles(this.translateService.currentLang);

   this.translateService.onLangChange.subscribe((event: LangChangeEvent) => {
      this.getArticles(this.articleType, this.translateService.currentLang);
      this.getElectricCarArticles(this.translateService.currentLang);
      this.getPrArticles(this.translateService.currentLang);
   });
  }

  private getArticles(articleType:any, lang:string){
    this.articles=[];
    this.getArticlesDTO(articleType, this.articles,lang)
  }

  private getElectricCarArticles(lang: string){
    this.electricCarArticles =[];
    this.getArticlesDTO(ArticleType.ELECTRIC_CAR, this.electricCarArticles, lang);
  }

  private getPrArticles(lang: string){
    this.prArticles =[];
    this.getArticlesDTO(ArticleType.P_R, this.prArticles, lang);
  }

  private getArticlesDTO(articleType: ArticleType, articles: ArticleDTO[], lang: string){
    this.articleService.getPublishedArticles(articleType, lang).subscribe(
      data => {
        for(let article of data as any[]){
          let articleDTO: ArticleDTO = {
            article: undefined,
            galleryImages: []
          }
          articleDTO.article = article
          articles.push(articleDTO);
        }
        if(articleType === ArticleType.NEWS){ 
          this.articlesFull = articles;
          this.search();
        }  
      }
    ).add(
      () => {
        for(let article of articles){
          for(let articleImage of article.article.articleImages)
          {
            this.mediaService.getMediaResponse(articleImage.id).subscribe(
              media => {
                let blobUrl = URL.createObjectURL(new Blob([media.body!]));
                article.galleryImages.push(this.domSanitizer.bypassSecurityTrustResourceUrl(blobUrl)); 
              }
            )
          }  
        }
      }
    );
  }

  private search(){
    this.commonService.getSearchText().subscribe(
      searchText => {
        if(searchText != null){   
          this.articles = this.articlesFull.filter(article => { 
            let text: string = new DOMParser().parseFromString(article.article.mainText, "text/html").documentElement.textContent?.toLowerCase() || '';
            return this.getTagsString(article).toLowerCase().includes(searchText.toLowerCase()) 
            || article.article.title.toLowerCase().includes(searchText.toLowerCase()) || text.includes(searchText.toLowerCase())});
        }
      }
    )
  }

  private getTagsString(article: any){
    let tag="";
    for(let t of article.article.articleTags){
      tag += t.title +" ";
    }
    return tag;
  }

  onClickTag(tag: string){
    this.articles = this.articlesFull.filter(article => { return  this.getTagsString(article).includes(tag)});
  }

  onClickArticle(index: number){
    this.show[index] = this.show[index] ?  false : true; 
  }
}
