import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { CommonService } from 'src/app/service/common.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  public searchForm: FormGroup;

  constructor(
    private commonService: CommonService
  ) {
    this.searchForm = new FormGroup({
      searchText: new FormControl('')
    })
   }

  ngOnInit(): void {
  }

  onClickSearch(){
    this.commonService.setSearchText(this.searchForm.controls.searchText.value);
  }


}

