
<nav class="navbar navbar-expand-lg fixed-top navbar-light">
    <a class="navbar-brand" href="#" style="margin-left: 20px; color: #1c2453; font-weight: bold; font-size: 40px;">BPO</a>
    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
    </button>

    <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="navbar-nav mr-auto">
            <li class="nav-item active">
                <a class="nav-link" href="articles/NEWS">{{"news" | translate}}</a>
            </li>  
            <li class="nav-item active">
                <a class="nav-link" href="shops/SHOP">{{"shops" | translate}}</a>
            </li> 
            <li class="nav-item active">
                <a class="nav-link" href="shops/RESTAURANT">{{"restaurants" | translate}}</a>
            </li>
            <li class="nav-item active">
                <a class="nav-link" href="shops/SERVICE">{{"services" | translate}}</a>
            </li>
            <li class="nav-item">
                <a class="nav-link" href="articles/ETELE">{{"Etele" | translate}}</a>
            </li>
            <li class="nav-item">
                <a class="nav-link" href="articles/WELL">{{"WELL" | translate}}</a>
            </li>     
            <li class="nav-item">
                <a class="nav-link" href="articles/PARKING">{{"parking" | translate}}</a>
            </li>
            <li class="nav-item">
                <a class="nav-link" href="articles/PUBLIC_TRANSPORTATION">{{"public_transportation" | translate}}</a>
            </li> 
            <li class="nav-item">
                <a class="nav-link" href="articles/BUILDING_INFO">{{"building_info" | translate}}</a>
            </li>     
        </ul>
        <ul class="navbar-nav ms-3">
            <li class="nav-item ms-3">
                <form class="d-flex" role="search" [formGroup]="searchForm">
                    <input class="form-control me-2" style="width: 200px;"  formControlName="searchText" placeholder='{{"search" | translate}}' aria-label="Search">
                    <button class="btn btn-outline-success" (click)="onClickSearch()">{{"search" | translate}}</button>
                </form>
            </li>   
        </ul>
        <ul class="navbar-nav ms-auto">
            <li class="nav-item ms-auto">
                <app-select-language></app-select-language>
            </li>
        </ul>
    </div>
   
</nav>

