import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';


const httpOptions = {
  reportProgress: true,
  headers: new HttpHeaders({"Content-Type": "undefined"})
}

@Injectable({
  providedIn: 'root'
})
export class MediaService {

  constructor(private http: HttpClient) { }

  getShopLogoImage(logoId: number){
    return this.http.get('/server/bpone/media/get_shop_logo_image/' + logoId, httpOptions);
  }

  getShopImages(shopId: number){
    return this.http.get('/server/bpone/shop_images/get_shop_images_by_shop_id/' + shopId, httpOptions);
  }

  getMediaResponse(mediaId: number){
    return this.http.get('/server/bpone/media/get_media_response/' + mediaId,  {
      reportProgress: true,
      responseType: 'blob',
      observe: 'response'
    });
  }
}
