
<app-header></app-header>
<div class="container mt-2">
  <div class="row app-contianer p-2">
      <div class="col-lg-10 offset-md-1">
        <router-outlet></router-outlet>
      </div>
  </div>
</div>
<app-footer></app-footer>

