<div class="container" style="align-content: center;">
  <img style="display: block;  margin: auto;" src="./assets/images/bpo.jpg" height="230px" width="250px;">
</div>
<mat-tab-group [selectedIndex]="0" style="background-color: rgb(255, 255, 255); margin-left: 40px;" mat-stretch-tabs>
  <mat-tab label='{{shopTitle | translate}}' disabled class="tab-label"> 
    <div class="row " style="width: 100%;">
      <div class="jumbotron-fluid shop" *ngFor="let shop of shops;  let i = index">
        <span>{{isOpen(i, shop.shop)}}</span>
        <div class="container" (click)="onClickDeatils(shop.shop.shopId)">
      
          <img class="logo-image" [src]="shop.logoImage"  height="60px" width="80px;">&nbsp;
          <i class="bi bi-circle-fill open-icon" *ngIf="open[i]"></i>
          <p style="float: right; color: rgb(19, 194, 19);" *ngIf="open[i]">{{shop.actualDayOpenHour}}</p>
          <i class="bi bi-circle-fill close-icon"  *ngIf="!open[i]"></i>
          <p style="float: right; color: rgb(207, 10, 10);" *ngIf="!open[i]">{{shop.actualDayOpenHour}}</p>
          
          <h2 style="font-weight:bold; margin-top: 50px;">{{shop.shop.name}}</h2>
        </div>
        <div >
          <p><i>{{"tags" | translate}}: </i><a class="tagClass" (click)="onClickTag(tag.title)" *ngFor="let tag of shop.shop.shopTags">#{{tag.title}} </a></p>
        </div>
      </div>
    </div>
  </mat-tab>
</mat-tab-group>
