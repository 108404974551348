import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ShopService {

  constructor(private http: HttpClient) { }

  getPublishedShops(shopType:any, lang: string){
    return this.http.get('/server/bpone/shops_translatable/get_published_shops_by_type/' + shopType + "/" + lang);
  }

  getShop(id: number, lang: string){
    return this.http.get('/server/bpone/shops_translatable/get_shop_translatable_by_shop_id_and_lang/' + id + "/" + lang);
  }


}
