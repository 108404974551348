export enum ArticleType {
    NEWS = "NEWS",
    PARKING = "PARKING",
    RECEPTION = "RECEPTION",
    PUBLIC_TRANSPORTATION ="PUBLIC_TRANSPORTATION",
    ETELE = "ETELE",
    ELECTRIC_CAR = "ELECTRIC_CAR",
    WELL ="WELL",
    BUILDING_INFO="BUILDING_INFO",
    P_R="P_R"
}

export enum ArticleStatus {
    PUBLISHED,
    DRAFT,
    HIDDEN
}