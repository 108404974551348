import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ArticleStatus, ArticleType } from '../global/global.enum';

const httpOptions = { headers: new HttpHeaders({'Content-Type': 'application/json'}) }

@Injectable({
  providedIn: 'root'
})
export class ArticleService {

  constructor(private http: HttpClient) { }

  getPublishedArticles(type:string, lang: string){
    return this.http.get('/server/bpone/articles_translatable/get_published_articles_by_type/' + type + "/" + lang);
  }

}
