import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { MediaService } from 'src/app/service/media.service';
import { OpenHoursService } from 'src/app/service/open-hours.service';
import { ShopService } from 'src/app/service/shop.service';

@Component({
  selector: 'app-shop',
  templateUrl: './shop.component.html',
  styleUrls: ['./shop.component.css']
})
export class ShopComponent implements OnInit {

  public shop: any

  public openHours: any[] = [];

  public isOpen: boolean = false;

  public openHoursView: string ="";

  public shopImages: any[] =[];

  public shopLogoUrl?: SafeResourceUrl;

  constructor(
      private shopService: ShopService,
      private openHoursService: OpenHoursService,
      private route: ActivatedRoute,
      private translateService: TranslateService,
      private mediaService: MediaService,
      private domSanitizer: DomSanitizer
    ) { }

  ngOnInit(): void {
    this.getShop(this.route.snapshot.params.id, this.translateService.currentLang);
   
    this.getOpenHoursByShopId(this.route.snapshot.params.id);
    this.translateService.onLangChange.subscribe((event: LangChangeEvent) => {
      this.getShop(this.route.snapshot.params.id, this.translateService.currentLang);
      this.getOpenHoursByShopId(this.route.snapshot.params.id);
    });
  }

  private getShop(id: number, lang: string){
    this.shopService.getShop(id, lang).subscribe(
      (data: any) => {
        this.shop = data;
        this.getLogoUrl(data.shop.logoId); 
        this.getShopImages(data.shopImages);
        this.checkIsOpen(data.shopId);     
      }
    )
  }

  private getLogoUrl(mediaId: number){
    this.mediaService.getMediaResponse(mediaId).subscribe(
      (media: any) => {
        let blobUrl = URL.createObjectURL(new Blob([media.body!]));
        this.shopLogoUrl = this.domSanitizer.bypassSecurityTrustResourceUrl(blobUrl);
      }
    )
  }

  private getShopImages(shopImages: any[]){
    for(let image of shopImages){
      this.mediaService.getMediaResponse(image.id).subscribe(
        media => {
          let blobUrl = URL.createObjectURL(new Blob([media.body!]));
          let shopImageUrl = this.domSanitizer.bypassSecurityTrustResourceUrl(blobUrl);
          this.shopImages.push(shopImageUrl)
        }
      )
    }
  }

  private checkIsOpen(shopId: number){
    let date= new Date();
    let actualOpenHour: any = {};
    let day= this.getDay(date.getDay());
    for(let openHour of this.openHours){
      if(openHour.shopId === shopId && openHour.whatWeekDay === day){
        actualOpenHour = openHour;
      }
    }
    if(date.getHours() >= actualOpenHour.openingHour && date.getHours() <= actualOpenHour.closeHour){
      this.isOpen = true; 
      if(date.getHours() == actualOpenHour.closeHour){
        this.isOpen = (date.getMinutes() < actualOpenHour.closeMinute) ? true : false; 
      }
      if(date.getHours() == actualOpenHour.openingHour){
        this.isOpen = (date.getMinutes() >= actualOpenHour.openingMinute) ? true : false;
      }
    }else{
      this.isOpen = false; 
    }
  }

  private getDay(day: number){
    switch(day){
      case 0:
        return 'SUNDAY';
      case 1:
        return 'MONDAY';
      case 2:
        return 'TUESDAY';
      case 3:
        return 'WEDNESDAY';
      case 4:
        return 'THURSDAY';
      case 5:
        return 'FRIDAY';
      case 6:
        return 'SATURDAY';
      default:
        return "";
    }
  }

  private getOpenHoursByShopId(shopId: number){
    this.openHoursService.getOpenHoursByShopId(shopId).subscribe(
      data => {
        this.openHours = data as any[];
        this.getOpenHoursView(data as any[]);
      }
    )
  }

  private getOpenHoursView(openHours: any[]){
    this.openHoursView = "";
    for(let o of openHours){
      this.openHoursView += 
      this.translateService.instant(o.whatWeekDay) + ": " 
      + (o.openingHour < 10 ? "0" + o.openingHour : o.openingHour) + ":" 
      + (o.openingMinute < 10 ? "0" + o.openingMinute : o.openingMinute)
      + " - " 
      + (o.closeHour < 10 ? "0" + o.closeHour : o.closeHour) + ":" 
      + (o.closeMinute < 10 ? "0" + o.closeMinute : o.closeMinute)  + "\n"
    }   
  }
}
