import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { ShopDTO } from 'src/app/models';
import { CommonService } from 'src/app/service/common.service';
import { MediaService } from 'src/app/service/media.service';
import { OpenHoursService } from 'src/app/service/open-hours.service';
import { ShopService } from 'src/app/service/shop.service';

@Component({
  selector: 'app-shops',
  templateUrl: './shops.component.html',
  styleUrls: ['./shops.component.css']
})
export class ShopsComponent implements OnInit {

  public shops: ShopDTO[] = [];
  public shopsFull: ShopDTO[] = [];

  public show: {[key: number]: boolean} = {};

  public open: {[key: number]: boolean} = {};
  
  public openHours: any[] =[];

  public shopType: any;
  public shopTitle: string ="";

  constructor(
    private shopService: ShopService,
    private openHoursService: OpenHoursService,
    private translateService: TranslateService,
    private router: Router,
    private route: ActivatedRoute,
    private commonService: CommonService,
    private mediaService: MediaService,
    private domSanitizer: DomSanitizer
    ) { }

  ngOnInit(): void {
    this.shopType = this.route.snapshot.params.shopType;
    this.getShopTitle();
    this.getOpenHours();
    this.getShops(this.translateService.currentLang);
    this.translateService.onLangChange.subscribe((event: LangChangeEvent) => {
      this.getShops(this.translateService.currentLang);
    });
   
  }

  private getShopTitle(){
    switch(this.shopType){
      case "SHOP":
        this.shopTitle ="shops";
        break;
      case "RESTAURANT":
        this.shopTitle="restaurants"
        break;
      default:
        this.shopTitle ="services";
        break;
     
    }
  }

  private getShops(lang:string){
    this.shopService.getPublishedShops(this.shopType, lang).subscribe(
      data => {
        this.shops =[];
        for(let shop of data as any[]){
          let shopDTO: ShopDTO={
            shop: undefined,
            logoImage: undefined,
            actualDayOpenHour: ""
          };
          shopDTO.shop = shop; 
          this.openHoursService.getOpenHours().subscribe(
            data => {
              let openHours = data as any[]
              let actualOpenHour = openHours.filter(o => {return o.whatWeekDay === this.getDay(new Date().getDay()) && o.shopId === shop.shopId})
              shopDTO.actualDayOpenHour = this.getOpenHoursView(actualOpenHour[0]);
             
            }
          )
          this.shops.push(shopDTO);  
        }  
      
        this.shopsFull = this.shops;
        this.search();
      }
    ).add(
      () => {
        for(let shop of this.shops){
          this.mediaService.getMediaResponse(shop.shop.shop.logoId).subscribe(
            media => {
              let blobUrl = URL.createObjectURL(new Blob([media.body!]));
              shop.logoImage = this.domSanitizer.bypassSecurityTrustResourceUrl(blobUrl);  
            }
          );
        }
      }
    );
  }

  private getOpenHours(){
    this.openHoursService.getOpenHours().subscribe(
      data => this.openHours = data as any[]
    )
  }

  private getOpenHoursView(o: any,){
    let openHoursView:string = "";
    openHoursView += 
    (o.openingHour < 10 ? "0" + o.openingHour : o.openingHour) + ":" 
    + (o.openingMinute < 10 ? "0" + o.openingMinute : o.openingMinute)
    + " - " 
    + (o.closeHour < 10 ? "0" + o.closeHour : o.closeHour) + ":" 
    + (o.closeMinute < 10 ? "0" + o.closeMinute : o.closeMinute)  + "\xa0"
    return openHoursView;
  }

  private search(){
    this.commonService.getSearchText().subscribe(
      searchText => {
        if(searchText != null){
          this.shops = this.shopsFull.filter(shop => { return this.getTagsString(shop).toLowerCase().includes(searchText.toLowerCase()) 
            || shop.shop.name.toLowerCase().includes(searchText.toLowerCase())});
        }
      }
    )
  }

  private getTagsString(shop: ShopDTO){
    let tag="";
    for(let t of shop.shop.shopTags){
      tag += t.title +" ";
    }
    return tag;
  }

  onClickLearnMore(index: number){
    this.show[index] = true;
  }

  onClickHide(index: number){
    this.show[index] = false; 
  }

  onClickDeatils(id: number){
    this.router.navigate(['/shop/' + id]);
  }

  onClickTag(tag:string){
    this.shops = this.shopsFull.filter(shop => { return this.getTagsString(shop).includes(tag)});
  }

  isOpen(index: number, shop: any){
    let date= new Date();
    let actualOpenHour: any = {};
    let day= this.getDay(date.getDay());
    for(let openHour of this.openHours){
      if(openHour.shopId === shop.shopId && openHour.whatWeekDay === day){
        actualOpenHour = openHour;
      }
    }
    if(date.getHours() >= actualOpenHour.openingHour && date.getHours() <= actualOpenHour.closeHour){
      this.open[index] = true; 
      if(date.getHours() == actualOpenHour.closeHour){
        this.open[index] = (date.getMinutes() < actualOpenHour.closeMinute) ? true : false; 
      }
      if(date.getHours() == actualOpenHour.openingHour){
        this.open[index] = (date.getMinutes() >= actualOpenHour.openingMinute) ? true : false;
      }
    }else{
      this.open[index] = false; 
    }
  }

  private getDay(day: number){
    switch(day){
      case 0:
        return 'SUNDAY';
      case 1:
        return 'MONDAY';
      case 2:
        return 'TUESDAY';
      case 3:
        return 'WEDNESDAY';
      case 4:
        return 'THURSDAY';
      case 5:
        return 'FRIDAY';
      case 6:
        return 'SATURDAY';
      default:
        return "";
    }
  }

}


