<div class="container" style="align-content: center;">
  <img style="display: block;  margin: auto;" src="./assets/images/bpo.jpg" height="240px" width="260px;">
</div>


<div *ngIf="!isParking">
  <mat-tab-group [selectedIndex]="0" style="background-color: rgb(255, 255, 255);"  mat-stretch-tabs>
    <mat-tab label='{{articleType | translate}}' disabled class="tab-label">
        <div class="jumbotron jumbotron-fluid article" *ngFor="let article of articles;  let index=index" >
          <div class="container">
            
             <h1 class="display-4" style="text-align: center;" (click)="onClickArticle(index)">{{article.article.title}}</h1><br/> 

            <div id="carouselExampleIndicators{{index}}" class="carousel slide height-40"  data-ride="carousel" (click)="onClickArticle(index)">
              <div class="carousel-inner">
                <div class="carousel-item" style="object-fit: contain;" *ngFor="let image of article.galleryImages; let i=index" [ngClass]="{'active': i == 0 }">
                  <img style="object-fit: contain;"  class="d-block w-100"  [src]="image">
                </div>
              </div>
              <a class="carousel-control-prev" href="#carouselExampleIndicators{{index}}" role="button" data-slide="prev">
                <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                <span class="sr-only">Previous</span>
              </a>
              <a class="carousel-control-next" href="#carouselExampleIndicators{{index}}" role="button" data-slide="next">
                <span class="carousel-control-next-icon" aria-hidden="true"></span>
                <span class="sr-only">Next</span>
              </a>
            </div><br/>

             <div [innerHTML]="article.article.mainText" *ngIf="show[index]"></div> 
          </div>
          <div style="margin:25px;">
            <p><i>{{"tags" | translate}}: </i><a class="tagClass" *ngFor="let tag of article.article.articleTags" (click)="onClickTag(tag.title)">#{{tag.title}} </a></p>
          </div>
        </div>
    </mat-tab>
  </mat-tab-group>
</div>

<div *ngIf="isParking">
  <mat-tab-group [selectedIndex]="0" style="background-color: rgb(255, 255, 255);">
    <mat-tab label='{{"parking" | translate}}'>   
      <div class="jumbotron jumbotron-fluid article" *ngFor="let article of articles; let index =index" >
        <div class="container"> 
              <h1 class="display-4" style="text-align: center;">{{article.article.title}}</h1><br/>

              <div id="carouselExampleIndicators{{index}}" class="carousel slide height-40"  data-ride="carousel">
                <div class="carousel-inner">
                  <div class="carousel-item" style="object-fit: contain;" *ngFor="let image of article.galleryImages; let i=index" [ngClass]="{'active': i == 0 }">
                    <img style="object-fit: contain;"  class="d-block w-100"  [src]="image">
                  </div>
                </div>
                <a class="carousel-control-prev" href="#carouselExampleIndicators{{index}}" role="button" data-slide="prev">
                  <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                  <span class="sr-only">Previous</span>
                </a>
                <a class="carousel-control-next" href="#carouselExampleIndicators{{index}}" role="button" data-slide="next">
                  <span class="carousel-control-next-icon" aria-hidden="true"></span>
                  <span class="sr-only">Next</span>
                </a>
              </div><br/>

              <div [innerHTML]="article.article.mainText"></div> 
        </div>
        <div style="margin:25px;">
          <p><i>{{"tags" | translate}}: </i><a class="tagClass" *ngFor="let tag of article.article.articleTags"  (click)="onClickTag(tag.title)">#{{tag.title}} </a></p>
        </div>
      </div>
    </mat-tab>
    <mat-tab label='{{"electricCar" | translate}}'>   
      <div class="jumbotron jumbotron-fluid article" *ngFor="let article of electricCarArticles; let index =index" >
        <div class="container"> 
              <h1 class="display-4" style="text-align: center;">{{article.article.title}}</h1><br/>

              <div id="carouselExampleIndicators{{index}}" class="carousel slide height-40"  data-ride="carousel">
                <div class="carousel-inner">
                  <div class="carousel-item" style="object-fit: contain;" *ngFor="let image of article.galleryImages; let i=index" [ngClass]="{'active': i == 0 }">
                    <img style="object-fit: contain;"  class="d-block w-100"  [src]="image">
                  </div>
                </div>
                <a class="carousel-control-prev" href="#carouselExampleIndicators{{index}}" role="button" data-slide="prev">
                  <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                  <span class="sr-only">Previous</span>
                </a>
                <a class="carousel-control-next" href="#carouselExampleIndicators{{index}}" role="button" data-slide="next">
                  <span class="carousel-control-next-icon" aria-hidden="true"></span>
                  <span class="sr-only">Next</span>
                </a>
              </div><br/>

              <div [innerHTML]="article.article.mainText"></div> 
        </div>
        <div style="margin:25px;">
          <p><i>{{"tags" | translate}}: </i><a class="tagClass" *ngFor="let tag of article.article.articleTags"  (click)="onClickTag(tag.title)">#{{tag.title}} </a></p>
        </div>
      </div>
    </mat-tab>
    <mat-tab label='{{"P_R" | translate}}'>   
      <div class="jumbotron jumbotron-fluid article" *ngFor="let article of prArticles; let index =index" >
        <div class="container"> 
              <h1 class="display-4" style="text-align: center;">{{article.article.title}}</h1><br/>

              <div id="carouselExampleIndicators{{index}}" class="carousel slide height-40"  data-ride="carousel">
                <div class="carousel-inner">
                  <div class="carousel-item" style="object-fit: contain;" *ngFor="let image of article.galleryImages; let i=index" [ngClass]="{'active': i == 0 }">
                    <img style="object-fit: contain;"  class="d-block w-100"  [src]="image">
                  </div>
                </div>
                <a class="carousel-control-prev" href="#carouselExampleIndicators{{index}}" role="button" data-slide="prev">
                  <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                  <span class="sr-only">Previous</span>
                </a>
                <a class="carousel-control-next" href="#carouselExampleIndicators{{index}}" role="button" data-slide="next">
                  <span class="carousel-control-next-icon" aria-hidden="true"></span>
                  <span class="sr-only">Next</span>
                </a>
              </div><br/>

              <div [innerHTML]="article.article.mainText"></div> 
        </div>
        <div style="margin:25px;">
          <p><i>{{"tags" | translate}}: </i><a class="tagClass" *ngFor="let tag of article.article.articleTags"  (click)="onClickTag(tag.title)">#{{tag.title}} </a></p>
        </div>
      </div>
    </mat-tab>
  </mat-tab-group>
</div>
