import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class CommonService {

  private searchText = new BehaviorSubject<string>("");

  constructor() { }

  setSearchText(searchText: string){
    this.searchText.next(searchText);
  }

  getSearchText(): Observable<string>{
    return this.searchText.asObservable();
  }

}
