import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class OpenHoursService {

  constructor(private http: HttpClient) { }

  getOpenHours(){
    return this.http.get('/server/bpone/open_hours');
  }

  getOpenHoursByShopId(shopId: number){
    return this.http.get('/server/bpone/open_hours/get_open_hours_by_shop_id/' + shopId);
  }

}
