<div class="jumbotron-fluid">
    <br/>
  <div class="container">
    <img style="vertical-align:middle;" [src]="shopLogoUrl" height="60px" width="80px;">&nbsp;
    <h1 style="vertical-align:middle;display:inline">{{shop.name}}</h1>
    <i class="bi bi-circle-fill open-icon"  *ngIf="isOpen"></i>
    <i class="bi bi-circle-fill close-icon"  *ngIf="!isOpen"></i>&nbsp;
    <hr class="my-4">
    <div id="carouselExampleIndicators" class="carousel slide height-40"  data-ride="carousel">
      <div class="carousel-inner">
        <div class="carousel-item" style="object-fit: contain;" *ngFor="let image of shopImages; let i=index" [ngClass]="{'active': i == 0 }">
          <img style="object-fit: contain;"  class="d-block w-100"  [src]="image">
        </div>
      </div>
      <a class="carousel-control-prev" href="#carouselExampleIndicators" role="button" data-slide="prev">
        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
        <span class="sr-only">Previous</span>
      </a>
      <a class="carousel-control-next" href="#carouselExampleIndicators" role="button" data-slide="next">
        <span class="carousel-control-next-icon" aria-hidden="true"></span>
        <span class="sr-only">Next</span>
      </a>
    </div>
    <hr class="my-4"><br/>  
    <div class="conatiner">
        <h2>{{"details" | translate}}</h2><br/>
        <div [innerHTML]="shop.description"></div><br/>
        <b>{{"openHours" | translate}}</b><br/>
        <div style="width: 200px; white-space: pre-line">{{openHoursView}}</div><br/>
        <b>{{"contact" | translate}}</b><br/>
        <p>{{"location" | translate}}: {{shop.location}}</p>
        <p>E-mail: {{shop.shop.email}}</p>
        <p>{{"phone" | translate}}: {{shop.shop.phone}}</p>
        <p>{{"website" | translate}}: {{shop.shop.website}}</p><br/>
    </div>
    <br/><br/>
  </div>
  <br/>
</div>