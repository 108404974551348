import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ArticlesComponent } from './components/articles/articles.component';
import { ShopComponent } from './components/shops/shop/shop.component';
import { ShopsComponent } from './components/shops/shops.component';

const routes: Routes = [ 
  {
    path: '',
    component: ArticlesComponent
  },
  {
    path: 'articles/:articleType',
    component: ArticlesComponent
  },
  {
    path: 'tag/:tag',
    component: ArticlesComponent
  },
  {
    path: 'shops',
    component: ShopsComponent
  },
  {
    path: 'shops/:shopType',
    component: ShopsComponent
  },
  {
    path: 'shop/:id',
    component: ShopComponent
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})


export class AppRoutingModule { }
