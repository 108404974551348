import {Component} from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'bponefepublic';
  
  constructor(private router: Router,){
    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false;
   };
  }
  
}
